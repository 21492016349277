import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import DataViewer from '~utils/DataViewer'
import Image from '~components/Image'
import Section from '~components/Section'
import RichText from '~components/RichText'
import Button from '~components/Button'
import SanityLink from '~components/SanityLink'
import HeaderMedia from '~components/HeaderMedia'
import BookSection from '~components/BookSection'

const Service = ({className, service}) => {
	const [hover, setHover] = useState()
	return (
		<ServiceWrap 
			className={className}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<ServiveLink link={service.link}>
				<ServiceTitle >{service.title}</ServiceTitle>
				<ServiceImage image={service.image} aspectRatio={1.65}/>
				<ServiceText><RichText content={service.text}/></ServiceText>
				<Button hover={hover} noLink>learn more</Button>
			</ServiveLink>
		</ServiceWrap>
	)
}

const ServiceWrap = styled.div`
	grid-column: span 4;
	${desktop}{
		grid-column: span 6;
		&:not(:last-of-type){
			margin-bottom: 38px;
		}
	}
	${mobile}{
		grid-column: span 16;
		&:not(:last-of-type){
			margin-bottom: var(--m-s);
		}
	}
`
const ServiveLink = styled(SanityLink)`
	
`
const ServiceTitle = styled.div`
	text-transform: uppercase;
	padding-top: 6px;
	margin-bottom: 30px;
	${tablet}{
		margin-bottom: 22px;
	}
`
const ServiceImage = styled(Image)`
	margin-bottom: 40px;
	${tablet}{
		margin-bottom: 22px;
	}
`
const ServiceText = styled.div`
	margin-bottom: 36px;
	${tablet}{
		margin-bottom: 22px;
	}
`

const Services = ({ data }) => {
	const page = data?.sanityServicesPage

	return (
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<Header headerMedia={page.headerMedia}/>
			<Intro>
				<IntroText	IntroText className='h1-serif'><RichText content={page.introText}/></IntroText>
			</Intro>
			<Section><Line/></Section>
			<Grid>
				<SideTitle className='h2-serif'>{page.servicesTitle}</SideTitle>
				<SubGrid>
					{page.services.map(service => (
						<Service key={service.title} service={service}/>
					))}
				</SubGrid>
			</Grid>
			<Section><Line/></Section>
			<Grid>
				<SideTitle className='h2-serif'>{page.capabilitiesTitle}</SideTitle>
				<SubGrid>
					{page.capabilities.map(capability => (
						<CapabilityColumn key={capability.title}>
							<CapabilitiesTitle>{capability.title}</CapabilitiesTitle>
							{capability.capabilities.map(capability => (
								<div key={capability}>{capability}</div>
							))}
						</CapabilityColumn>
					))}
				</SubGrid>
			</Grid>
			<Section><Line/></Section>
			<BookSection />
			<DataViewer data={page} name="page" />
		</>
	)
}

const Header = styled(HeaderMedia)`
`
const Intro = styled(Section)`
	padding-top: var(--m);
	margin-bottom: var(--l);
	${mobile}{
		padding-top: var(--m-m);
    margin-bottom: var(--m-xl);
	}
`
const IntroText = styled.div`
	grid-column: span 16;
`
const Line = styled.div`
	border-bottom: 1px solid var(--black);
	grid-column: span 16;
`
const Grid = styled(Section)`
	padding-top: var(--s);
	margin-bottom: var(--m);
	${mobile}{
		padding-top: var(--m-m);
		margin-bottom: var(--m-l);
	}
`
const SubGrid = styled.div`
	grid-column: 5/17;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 40px;
	${mobile}{
		grid-column: span 16;
		grid-template-columns: repeat(16, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 0px;
	}
`
const SideTitle = styled.h2`
	grid-column: span 4;
	${mobile}{
		grid-column: span 16;
		margin-bottom: var(--m-s);
	}
`
const CapabilityColumn = styled.div`
	grid-column: span 4;
	${desktop}{
		grid-column: span 6;
		&:not(:last-of-type){
			margin-bottom: 38px;
		}
	}
	${mobile}{
		grid-column: span 16;
		&:not(:last-of-type){
			margin-bottom: var(--m-s);
		}
	}
`
const CapabilitiesTitle = styled.div`
	text-transform: uppercase;
	padding-top: 6px;
	margin-bottom: 37px;
	${tablet}{
		margin-bottom: 22px;
	}
`

Services.propTypes = {
	data: PropTypes.object,
}
Service.propTypes = {
	className: PropTypes.string,
	service: PropTypes.object,
}

export const query = graphql`
	  query ServicesQuery($id: String) {
    	sanityServicesPage(id: {eq: $id}) {
				title
				slug {
					current
				}
				headerMedia{
					...headerMedia
				}
				introText: _rawIntroText(resolveReferences: {maxDepth: 5})
				servicesTitle
				services {
					title
					text: _rawText(resolveReferences: {maxDepth: 5})
					image{
						...imageWithAlt
					}
					link {
						...link
					}
				}
				capabilitiesTitle
				capabilities {
					title
					capabilities
					_key
				}
				seo{
					...seo
				}
			}
		}
`


export default Services