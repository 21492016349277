import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import Button from '~components/Button'
import Section from '~components/Section'
import RichText from '~components/RichText'


const BookSection = ({ className }) => {
	const {
		bookingButton,
		bookingText,
	} = useSiteSettings()

	return (
		<Wrap className={className}>
			<Container>
				<Text className='h1-serif'>
					<RichText content={bookingText}/>	
				</Text>
				<Button 
					link={bookingButton.link}
				>
					{bookingButton.text}
				</Button>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xl) 0;
	${mobile}{
		padding: var(--m-xl) 0;
	}
`
const Container = styled.div`
	grid-column: span 16;
	justify-self: center;
	text-align: center;
	max-width: 1000px;
	margin: 0 auto;
`
const Text = styled.div`
	margin-bottom: 40px;
	${mobile}{
		margin-bottom: 32px;
	}
`

BookSection.propTypes = {
	className: PropTypes.string
}

export default BookSection